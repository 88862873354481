import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor() { }

  isAndroid(): boolean {
    return /Android/i.test(navigator.userAgent);
  }

  isIos(): boolean {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

}
